import { create } from 'zustand';

interface ModalsStore {
  showLogin: boolean;
  showRegistration: boolean;
  showLogedInBox: boolean;
  showDeleteAd: boolean;
  showOrder: boolean;
  orderAd: number;

  setShowLogin: (show: boolean) => void;
  setShowRegistration: (show: boolean) => void;
  setShowLogedInBox: (show: boolean) => void;
  setShowDeleteAd: (show: boolean) => void;
  setShowOrder: (ad: number) => void;
}

const useModalsStore = create<ModalsStore>((set) => {
  return {
    showLogin: false,
    showRegistration: false,
    showLogedInBox: false,
    showDeleteAd: false,
    showOrder: false,
    orderAd: -1,

    setShowLogin: (showLogin) => set({ showLogin }),
    setShowRegistration: (showRegistration) => set({ showRegistration }),
    setShowLogedInBox: (showLogedInBox) => set({ showLogedInBox }),
    setShowDeleteAd: (showDeleteAd) => set({ showDeleteAd }),
    setShowOrder: (ad) => set({ showOrder: ad >= 0, orderAd: ad }),
  };
});

export type { ModalsStore };
export { useModalsStore };

import { useParams } from 'react-router-dom';

import { AdPage } from 'components/ad-page/ad-page.component';
import { useAd } from 'services/ads.service';
import { Main } from '../../layouts/main/main.component';
import { WhiteBackground } from 'layouts/backgrounds/white-background/white-background.component';

const LookAd = () => {
  const params = useParams();
  const id = params.id || '0';
  const { data, loaded } = useAd(id);

  return (
    <Main backgroundComp={WhiteBackground}>
      <AdPage data={data} loaded={loaded} />
    </Main>
  );
};

export default LookAd;

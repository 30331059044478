import { useDebouncedCallback } from '@react-hookz/web';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AdList } from 'components/ad-list/ad-list.component';
import { Card } from 'components/card/card.component';
import { Column, Grid } from 'components/grid/grid.component';
import { Pagination } from 'components/pagination/pagination.component';
import { SearchBar } from 'components/searchbar/searchbar.component';
import { Spinner } from 'components/spinner/spinner.component';
import { PriceType } from 'constants/price-type.enum';
import { FeathersBackground } from 'layouts/backgrounds/feathers-background/feathers-background.component';
import { Main } from 'layouts/main/main.component';
import { PageHeader } from 'layouts/page-header/page-header.component';
import { useAdsStore } from 'services/ads.service';
import { AdsFilterModal } from './components/ads-filter-modal.component';
import { AdsFilterSummary } from './components/ads-filter-summary.component';
import { AdsFilter } from './components/ads-filter.component';
import style from './search-ads.module.scss';

export const SearchAds: React.FC = () => {
  const [filter, query, setQuery] = useAdsStore((state) => [state.filter, state.searchstring, state.setSearchstring]);
  const [page, setPage, perPage] = useAdsStore((state) => [state.page, state.setPage, state.perPage]);
  const [data, allData, count, loaded, loading] = useAdsStore((state) => [
    state.data,
    state.allData,
    state.count,
    state.loaded,
    state.loading,
  ]);
  const [searchstring, setSearchstring] = useState<string>(query);

  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

  const getFilterCount = (): number => {
    const { categories, placeId, type, priceType, ...price } = filter;
    let count = (categories?.length || 0) + (placeId?.length || 0) + (placeId ? 1 : 0) + (priceType ? 1 : 0);
    if (priceType !== PriceType.Fixed && price.hourlyPriceFrom && price.hourlyPriceTo) {
      count++;
    }
    if (priceType !== PriceType.Hourly && price.fixedPriceFrom && price.fixedPriceTo) {
      count++;
    }
    return count;
  };

  const setDelaydQuery = useDebouncedCallback(
    (searchstring: string) => {
      setQuery(searchstring);
    },
    [],
    250,
    500
  );

  const handleSearchstring = (string: string) => {
    setSearchstring(string);
    setDelaydQuery(string);
  };

  useEffect(() => {
    setSearchstring(query);
  }, [query]);

  return (
    <Main>
      <PageHeader backgroundComp={FeathersBackground}>
        <Grid>
          <Column span={12} flex="column">
            <div className={style.header}>
              <h1 className="heading-standard">Vad vill du svippa?</h1>
              <p>Här kan du köpa och sälja tjänster på en och samma plats.</p>
            </div>
          </Column>
        </Grid>
      </PageHeader>
      <Grid className={style.maingrid}>
        <Column span={8} start={3} className={style.hidemobile}>
          <SearchBar
            placeholder="Sök efter tjänster eller förfrågningar..."
            size="large"
            value={searchstring}
            onChange={handleSearchstring}
          />
        </Column>

        <Column span={12} className={classNames(style.hidedesktop, style.column)}>
          <div className={style.row}>
            <SearchBar
              placeholder="Sök efter tjänster eller förfrågningar..."
              size="standard"
              value={searchstring}
              onChange={handleSearchstring}
            />
            <button onClick={() => setShowFilterModal(true)} className={classNames('primary large', style.hidexsmall)}>
              Filter {getFilterCount() > 0 && `(${getFilterCount()})`}
            </button>
            <button onClick={() => setShowFilterModal(true)} className={classNames('primary', style.showxsmall)}>
              Filter {getFilterCount() > 0 && `(${getFilterCount()})`}
            </button>
          </div>
          <AdsFilterSummary filter={filter} />
        </Column>
        <Column span={4} className={style.hidemobile}>
          <Card>
            <AdsFilter />
          </Card>
        </Column>
        {!loaded || loading ? (
          <Column span={[8, 8, 12, 4]}>
            <Spinner size="large" />
          </Column>
        ) : (
          <>
            <Column span={8} className={style.hidemobile}>
              <AdList
                ads={data}
                errorMessage={
                  <>
                    Inga annonser{' '}
                    {searchstring && (
                      <span>
                        innhållande sökord <i>{searchstring}</i>
                      </span>
                    )}
                    .
                  </>
                }
              />
              <Pagination count={count} perPage={perPage} page={page} onChangePage={setPage}></Pagination>
            </Column>
            <Column span={12} className={classNames(style.hidedesktop)}>
              <AdList
                ads={Object.keys(allData)
                  .filter((key, index) => index <= page)
                  .map((key) => allData[parseInt(key, 10)])
                  .flat()}
                errorMessage={
                  <>
                    Inga annonser{' '}
                    {searchstring && (
                      <span>
                        innhållande sökord <i>{searchstring}</i>
                      </span>
                    )}
                    .
                  </>
                }
              ></AdList>
            </Column>
            <Column span={12} className={classNames(style.hidedesktop)}>
              <Pagination count={count} perPage={perPage} page={page} onChangePage={setPage}></Pagination>
            </Column>
          </>
        )}
      </Grid>
      <AdsFilterModal open={showFilterModal} onClose={() => setShowFilterModal(false)} />
    </Main>
  );
};

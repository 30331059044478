import { useCallback } from 'react';
import style from './CreateAccountCTA.module.scss';
import { useModalsStore, useUserStore } from 'stores';
import { Link } from 'react-router-dom';

const CreateAccountCTA = () => {
  const setShowRegistration = useModalsStore((state) => state.setShowRegistration);
  const user = useUserStore((state) => state.user);

  const handleRegistration = useCallback(() => {
    setShowRegistration(true);
  }, [setShowRegistration]);

  return (
    <div className={style.infoSection}>
      <h3 className={style.title}>
        {user
          ? 'Börja skapa och bli en del av vår community!'
          : 'Skapa ett konto gratis idag och bli en del av familjen'}
      </h3>
      <p className={style.subtitle}>
        {user
          ? 'Utforska fler tjänster och påbörja ditt nästa projekt med oss.'
          : 'Upptäck hur enkelt det är att förvandla dina projekt eller uppgifter till verklighet med hjälp av en Svippare.'}
      </p>
      <div className={style.buttonwrapper}>
        {user ? (
          <Link to="/create-ad" className="button solid blue">
            Skapa inlägg
          </Link>
        ) : (
          <button className="button solid blue" onClick={handleRegistration}>
            Skapa konto
          </button>
        )}

        <Link to="/search" className={`button outlined blue`}>
          Utforska
        </Link>
      </div>
    </div>
  );
};

export default CreateAccountCTA;

import { useMemo, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Accordion } from 'components/accordion/accordion.component';
import { AdsFilterOptions, AdsPriceFilter, useAdsStore } from 'services/ads.service';
import { AdsFilterAdType } from './ads-filter-adtype.component';
import { AdsFilterCategories } from './ads-filter-categories.component';
import { AdsFilterLocations } from './ads-filter-location.component';
import { AdsFilterPrice } from './ads-filter-price.component';
import { AdsFilterSummary } from './ads-filter-summary.component';
import style from './ads-filter.module.scss';

const parseQuery = (params: URLSearchParams): AdsFilterOptions => {
  const type = params.get('type') as AdsFilterOptions['type'];
  const categories = params.get('categories')?.split(',').map(Number) || [];
  const placeId = params.get('placeId')?.split(',').map(Number) || [];
  return {
    ...(type && { type }),
    ...(categories.length && { categories }),
    ...(placeId.length && { placeId }),
  };
};

const buildQuery = (filter: AdsFilterOptions): URLSearchParams => {
  const params = new URLSearchParams();
  if (filter.type) params.set('type', filter.type.toString());
  if (filter.categories?.length) params.set('categories', filter.categories.join(','));
  if (filter.placeId?.length) params.set('placeId', filter.placeId.join(','));
  return params;
};

export const AdsFilter: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useAdsStore((state) => [state.filter, state.setFilter]);
  const { type, categories, placeId, ...priceFilter } = useMemo(() => filter, [filter]);
  const [priceOpen, setPriceOpen] = useState<boolean>(false);

  useEffect(() => {
    const initialFilter = parseQuery(searchParams);
    setFilter(initialFilter);
  }, []);

  useEffect(() => {
    const urlFilter = parseQuery(searchParams);
    if (
      JSON.stringify(urlFilter) !==
      JSON.stringify({ type: filter.type, categories: filter.categories, placeId: filter.placeId })
    ) {
      let priceFilter = {};
      if (filter.priceType || filter.hourlyPriceFrom || filter.hourlyPriceTo || filter.fixedPriceFrom || filter.fixedPriceTo) {
        priceFilter = {
          priceType: filter.priceType,
          hourlyPriceFrom: filter.hourlyPriceFrom,
          hourlyPriceTo: filter.hourlyPriceTo,
          fixedPriceFrom: filter.fixedPriceFrom,
          fixedPriceTo: filter.fixedPriceTo,
        };
      }
      setFilter({ ...urlFilter, ...priceFilter });
    }
  }, [searchParams, filter, setFilter]);

  const updateSearchParams = (addition: Partial<AdsFilterOptions>) => {
    setSearchParams(buildQuery({ ...filter, ...addition }), { replace: true });
  };

  const setPriceFilter = (priceFilter: AdsPriceFilter) => {
    setFilter({ type, categories, placeId, ...priceFilter });
  };

  const handleRemoveFilter = (newFilter: AdsFilterOptions) => {
    updateSearchParams(newFilter);
    const priceFilter = {
      priceType: newFilter.priceType,
      hourlyPriceFrom: newFilter.hourlyPriceFrom,
      hourlyPriceTo: newFilter.hourlyPriceTo,
      fixedPriceFrom: newFilter.fixedPriceFrom,
      fixedPriceTo: newFilter.fixedPriceTo,
    };
    setFilter({ ...filter, ...priceFilter });
  };

  return (
    <div className={style.column}>
      <Accordion label="Annonstyp" open={true}>
        <AdsFilterAdType value={type} onChange={(type) => updateSearchParams({ type: type })} />
      </Accordion>
      <Accordion label="Kategorier" open={true}>
        <AdsFilterCategories
          value={categories || []}
          onChange={(categories) => updateSearchParams({ categories: categories })}
        />
      </Accordion>
      <Accordion label="Plats" open={false}>
        <AdsFilterLocations value={placeId || []} onChange={(places) => updateSearchParams({ placeId: places })} />
      </Accordion>
      <Accordion label="Pris" open={false} onToggle={setPriceOpen}>
        <AdsFilterPrice value={priceFilter} onChange={setPriceFilter} />
      </Accordion>
      {priceOpen && <hr />}
      <AdsFilterSummary filter={filter} onChange={handleRemoveFilter} />
    </div>
  );
};

import logoSrc from 'assets/logos/logo.svg';
import classNames from 'classnames';
import { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import { AdType } from 'constants/ad-type.enum';
import styles from './Footer.module.scss';
import theme from 'styles/theme.module.scss';
import { useMediaQuery } from '@react-hookz/web';
import { useUserStore } from 'stores';
import { useAdsStore } from 'services/ads.service';

interface FooterProps extends HTMLProps<HTMLDivElement> {}

const Footer = (props: FooterProps) => {
  const { className } = props;
  const isTablet = useMediaQuery(`screen and (max-width: 1023px)`);
  // const isSmallLaptop = useMediaQuery(`screen and (min-width: 1024px) and (max-width: ${theme['breakpoint-m']})`);
  const isMobile = useMediaQuery(`screen and (max-width:${theme['breakpoint-s']})`);
  const [setSearchstring, setFilter] = useAdsStore((state) => [state.setSearchstring, state.setFilter]);
  const user = useUserStore((state) => state.user);

  const handleCategoryClick = (categoryIds: number[]) => {
    setSearchstring('');
    setFilter({
      type: AdType.Service,
      placeId: user?.placeId ? [user.placeId, 0] : undefined,
      categories: categoryIds,
    });
  };

  return (
    <div className={classNames(styles.container, className)} style={props.style}>
      <div className={styles.footer}>
        <div className={styles.topFooter}>
          {!isTablet && (
            <div className={styles.leftColumn}>
              <Link to="/">
                <img src={logoSrc} alt="logo" className={styles.logo} />
              </Link>
              <span>Tillsammans kan vi forma framtiden</span>
            </div>
          )}
          <div className={styles.rightColumn}>
            <div className={styles.footerLinks}>
              <div className={styles.footerCol}>
                <h3>Om Oss</h3>
                <ul>
                  {/* <li>
                    <Link to="/">Om oss</Link>
                  </li>
                  <li>
                    <Link to="/">Vår historia</Link>
                  </li> */}
                  <li>
                    <Link to="https://info.svippo.se/blogg/" target="_blank">
                      Blogg
                    </Link>
                  </li>
                </ul>
              </div>
              <div className={styles.footerCol}>
                <h3>Svippa</h3>
                <ul>
                  <li>
                    <Link to="/search?categories=1">
                      Digitala tjänster
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?categories=2">
                      Medie och design
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?categories=3">
                      Utbildningar
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?categories=4">
                      Hushållstjänster
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?categories=5">
                      Biltjänster
                    </Link>
                  </li>
                  <li>
                    <Link to="/search?categories=6">
                      Skönhet och hälsa
                    </Link>
                  </li>
                </ul>
              </div>
              <div className={styles.footerCol}>
                <h3>Hjälp</h3>
                <ul>
                  <li>
                    <Link to="https://info.svippo.se/svippare/" target="_blank">
                      Vara svippare
                    </Link>
                  </li>
                  <li>
                    <Link to="https://info.svippo.se/bestallare/" target="_blank">
                      Vara beställare
                    </Link>
                  </li>
                  <li>
                    <Link to="https://info.svippo.se/foretag/" target="_blank">
                      Vara företag
                    </Link>
                  </li>
                  <li>
                    <Link to="https://info.svippo.se/faq/" target="_blank">
                      FAQ
                    </Link>
                  </li>
                </ul>
              </div>
              <div className={styles.footerCol}>
                <h3>Kontakt</h3>
                <ul>
                  <li>
                    <div>
                      <span>
                        E-post <br />
                      </span>
                      <a href="mailto:kontakt@svippo.se">kontakt@svippo.se</a>
                    </div>
                  </li>
                  <li>
                    <span>
                      Telefon <br />
                    </span>
                    <a href="tel:020-105707">020-105 707</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {(isMobile || isTablet) && (
            <div className={styles.footerLogo}>
              <Link to="/">
                <img src={logoSrc} alt="logo" className={styles.logo} />
              </Link>
              <span>Tillsammans kan vi forma framtiden</span>
            </div>
          )}
        </div>
        <div className={styles.bottomFooter}>
          <span>Copyright av Svippo 2024</span>
        </div>
      </div>
    </div>
  );
};

export { Footer };

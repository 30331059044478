import { FormikHelpers } from 'formik';

import style from './order-modal.module.scss';
import { Modal } from '../modal.component';
import { OrderForm } from '../../forms';
import { useModalsStore, useUserStore } from '../../../stores';
import { submitOrder } from 'services/order.service';
import { OrderPayload } from 'interfaces/order.interface';

interface DeleteModalProps {
  onClose?: () => void;
}

const OrderModal = (props: DeleteModalProps) => {
  const { onClose } = props;
  const open = useModalsStore((state) => state.showOrder);
  const setShowOrder = useModalsStore((state) => state.setShowOrder);
  const orderAd = useModalsStore((state) => state.orderAd);
  const { user } = useUserStore();

  const handleClose = () => {
    setShowOrder(-1);
    onClose?.();
  };

  const handleSubmit = async (fields: OrderPayload, helpers: FormikHelpers<OrderPayload>) => {
    console.log('Form submitted:', fields);
    submitOrder(fields);
    handleClose();
  };

  const payload = {
    name: user?.publicName ?? '',
    email: user?.email ?? '',
    phone: user?.answerPhone ?? '',
    comment: '',
    terms: false,
  };

  return (
    <Modal title="Kontakta" open={open} onClose={handleClose}>
      <div className={style.serviceInfo}>
        <p>
          <strong>Hej👏</strong>
        </p>
        <h3>Vad kul att du vill kontakta mig</h3>
        <p>Fyll i Formuläret så kontaktar jag dig så snabbt jag kan!</p>
      </div>
      <OrderForm onValidSubmit={handleSubmit} payload={payload} ad={orderAd} />
    </Modal>
  );
};

export { OrderModal };

import React from 'react';
import { Formik, Form, FormikHelpers, Field, FieldProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import { Error } from '@mui/icons-material';

import { FormField } from '../form-field';
import styleFromFormField from 'components/forms/form-field/form-field.module.scss';
import style from '../forms.module.scss';
import { OrderPayload } from 'interfaces/order.interface';

interface OrderFormProps extends HTMLProps<HTMLDivElement> {
  onValidSubmit: (values: OrderPayload, helpers: FormikHelpers<OrderPayload>) => void;
  payload?: Partial<OrderPayload>;
  ad?: number;
}

// Validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string().required('Ditt namn krävs.'),
  email: Yup.string().email('Ogiltig E-postadress').required('E-postadress krävs.'),
  phone: Yup.string()
    .matches(/^[0-9\s\-+]{6,}$/, 'Ogiltigt telefonnummer')
    .required('Ditt telefonnummer krävs.'),
  comment: Yup.string(),
  terms: Yup.boolean().required('Du måste acceptera villkoren.').isTrue('Du måste acceptera villkoren.'),
});

const OrderForm: React.FC<OrderFormProps> = ({ onValidSubmit, payload, ad }) => { // Wierd with payload!!!
  const initialValues: OrderPayload = {
    name: payload?.name ?? '',
    email: payload?.email ?? '',
    phone: payload?.phone ?? '',
    comment: payload?.comment ?? '',
    terms: payload?.terms ?? false,
    ad: ad ?? -1,
  };

  return (
    <div className={style.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={onValidSubmit}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
      >
        {({ handleChange, handleBlur, isValid, dirty }) => (
          <Form>
            <FormField
              fieldSize="large"
              label="Ditt Namn"
              type="text"
              name="name"
              id="name"
              placeholder="John Doe"
              className={style.field}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <FormField
              fieldSize="large"
              label="Din E-post"
              type="email"
              name="email"
              id="email"
              placeholder="example@example.com"
              className={style.field}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <FormField
              fieldSize="large"
              label="Ditt Telefonnummer"
              type="tel"
              name="phone"
              id="phone"
              placeholder="070 123 45 67"
              className={style.field}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <FormField
              fieldSize="large"
              label="Meddelande"
              type="textarea"
              name="comment"
              id="comment"
              placeholder="Har du något att lägga till?"
              className={style.field}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <div className={style.checkbox}>
              <Field name="terms">
                {({ field }: FieldProps) => (
                  <>
                    <input type="checkbox" id="terms" checked={field.value} {...field} />
                    <label htmlFor="terms">
                      <span>
                        Jag accepterar dessa{' '}
                        <Link to="https://info.svippo.se/allmanna-villkor" target="_blank" rel="noopener noreferrer">
                          villkor
                        </Link>
                      </span>
                    </label>
                  </>
                )}
              </Field>
            </div>

            <ErrorMessage name="terms">
              {(error: string) => (
                <div className={`${styleFromFormField.error}`}>
                  <Error className={style.svgError} />
                  {error}
                </div>
              )}
            </ErrorMessage>

            <button type="submit" className="primary">
              Skicka
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { OrderForm };
